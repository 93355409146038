<template>
  <div class="wrap">
    <div class="card-1">
      <div class="title">直播信息</div>
      <a-spin :spinning="spinning" size="large" class="spin-css"></a-spin>
      <div class="content" v-if="!spinning && liveInfo">
        <div class="item1">直播ID：{{ liveInfo.roomId }}</div>
        <div class="item1">直播标题：{{ liveInfo.roomName }}</div>
        <div class="item1">主播昵称：{{ liveInfo.anchorName}}</div>
        <div class="item1">直播时长：{{ liveInfo.durationTime }}</div>
      </div>
    </div>

    <div class="card-1">
      <div class="title">
        数据概况 
        <a-tooltip placement="rightBottom">
          <template slot="title">
            <p>说明：直播数据待该场直播结束后进行统计。</p>
            <p>总销售额：该场直播订单总销售额。</p>
            <p>已过售后销售额：该场直播订单已过售后销售额。</p>
            <p>待过售后销售额：该场直播订单待过售后销售额。</p>
            <p>退款额：该场直播订单退款销售额。</p>
            <p>参与分享人数：该场直播参与分享直播间、分享直播间商品人数。</p>
            <p>支付人数：该场直播成功支付订单人数。</p>
          </template>
          <a-icon type="question-circle" style="font-size:16px;cursor: pointer;"/>
        </a-tooltip>
        
      </div>
      <a-spin :spinning="spinning" size="large" class="spin-css"></a-spin>
      <div class="content"  v-if="!spinning && generalInfo">
        <div class="item">
          <div class="tit">总销售额</div>
          <div class="total" :style="{color:colors[0]}">{{ generalInfo.orderPriceTotal || 0 }}</div>
          <div class="bot">订单数：{{ generalInfo.orderNum }}</div>
        </div>
        <div class="item">
          <div class="tit">已过售后销售额</div>
          <div class="total" :style="{color:colors[1]}">{{ generalInfo.orderPriceTotalAftersale || 0 }}</div>
          <div class="bot">订单数：{{ generalInfo.orderNumAftersale }}  占比：{{ !generalInfo.orderNumRateAftersale.includes('NaN') ? generalInfo.orderNumRateAftersale : 0}}</div>
        </div>
        <div class="item">
          <div class="tit">待过售后销售额</div>
          <div class="total" :style="{color:colors[2]}">{{ generalInfo.orderPriceTotalNotAftersale || 0 }}</div>
          <div class="bot">订单数：{{ generalInfo.orderNumNotAftersale }}  占比：{{ !generalInfo.orderNumRateNotAftersale.includes('NaN') ? generalInfo.orderNumRateNotAftersale : 0 }}</div>
        </div>
        <div class="item">
          <div class="tit">退款额</div>
          <div class="total" :style="{color:colors[3]}">{{ generalInfo.orderPriceTotalRefund || 0 }}</div>
          <div class="bot">订单数：{{ generalInfo.orderNumRefund }}  占比：{{ !generalInfo.orderNumRateRefund.includes('NaN') ? generalInfo.orderNumRateRefund : 0}}</div>
        </div>
        <div class="item">
          <div class="tit">参与分享人数</div>
          <div class="total">{{ generalInfo.shareNum || 0 }}</div>
        </div>
        <div class="item">
          <div class="tit">支付人数</div>
          <div class="total">{{ generalInfo.orderPayUserTotal || 0 }}</div>
        </div>
      </div>
    </div>

    <div class="card-1" style="margin-bottom:100px">
      <div class="title">趋势分析</div>
      <a-spin :spinning="spinning" size="large" class="spin-css"></a-spin>
      <div class="content" v-if="!spinning">
        <chart :chart_data="orderInfo"></chart>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api';
import { SUCCESS_CODE } from '@/config';
import chart from './chart.vue'
export default{
  components:{
    chart
  },
  data(){
    return{
      detail:false,
      spinning:true,
      colors:['#1890FF','#86C264','#FACC49','#FCA77E'],
      liveInfo:null,
      orderInfo:null,
      generalInfo:null
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      const roomId = this.$route.query.roomId
      if(!roomId) {
        this.$message.error("找不到roomId，请从表格 ‘数据’ 按钮进入！");
        return
      }
      const params = {
        roomId
      }
      this.spinning = true
      try {
        const { code, data } = await api.operationManage.shareCount(params);
        if (code === SUCCESS_CODE) {
          const { liveInfo, generalInfo, orderInfo } = data;
          this.liveInfo = liveInfo
          this.orderInfo = orderInfo
          this.generalInfo = generalInfo
        }
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
      
    }
  }
}
</script>
<style lang="less" scoped>
.spin-css{
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px 50px;
  margin: 0px auto;
}
.wrap{
  .title{
    font-size: 18px;
    font-weight: bold;
  }
  .card-1{
    width: 100%;
    height: auto;
    margin: 20px 0;
    padding: 10px 20px;
    
    background-color: #fff;
    overflow: hidden;
    .content{
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      margin-left: -20px;
      padding-bottom:5px;
      .item1{
        width: 25%;
        height: 25px; 
        border-right:1px solid #eee ;
        margin-top: 15px;
        padding-left:20px;
      }
      .item{
        width: 25%;
        height: 100px; 
        border-right:1px solid #eee ;
        margin-top: 15px;
        padding-left:20px ;
        .tit{
          color:#999
        }
        .total{
          font-size: 22px;
          font-weight: bold;
          margin-top:3px;
        }
        .bot{
          margin-top: 3px;
          color:#888
        }
        
      }
    }
  }
}
</style>