<template>
    <div class="wrap">
      <a-spin :spinning="spinning" size="large">
        <div class="line-chart" id="lineChart" v-if="chart_data && chart_data.xAxis"></div>
        <div v-else style="margin-left:11px">暂无数据</div>
      </a-spin>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    name: 'lineChart',
    props: {
      chart_data: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        spinning: false,
        chart: null,
       
      };
    },
    watch: {
      chart_data: {
        handler() {
          this.initChart();
        },
        deep: true
      }
    },
    mounted() {
      this.initChart();
    },
    updated() {
      this.initChart();
    },
    methods: {
      // 渲染图表
      initChart() {
        let chartDom = document.getElementById('lineChart');
        if (chartDom) {
          setTimeout(() => {
            this.chart = echarts.init(chartDom, { renderer: 'svg' });
            this.chart.setOption(this.getOptions());
            window.addEventListener('resize', () => {
              this.chart.resize();
              this.chart.setOption(this.getOptions());
            });
          }, 100);
        }
      },
      // 处理charts options
      getOptions() {
        const { xAxis, time, value1, value2,value3  } = this.chart_data;
        const option = {
          title: {
            text: '',
            textStyle: {
              color: '#666'
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '8%'
          },
          legend: {
            data: [
              '销售额',
              '支付单数',
            ]
          },
          xAxis: {
            type: 'category',
            data: value3,
            axisPointer: {
              type: 'shadow'
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '销售额',
              axisLabel: {
                formatter: '{value}'
              },
              // alignTicks: false,
            },
            {
              type: 'value',
              name: '支付单数',
              axisLabel: {
                formatter: '{value}'
              },
              alignTicks: true,
            }
          ],
          series: [
            // 折线图
            {
              name: '销售额',
              type: 'line',
              yAxisIndex: 0,
              data: value2
            },
            {
              name: '支付单数',
              type: 'line',
              yAxisIndex: 1,
              data: value1,
              
            },
   
          ]
        };
        return option;
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  .wrap {
    width: 100%;
    height: 95%;
    padding: 10px;
    .ant-spin-nested-loading {
      height: 100%;
    }
    .title {
      font-size: 20px;
      color: #555;
    }
  }
  .line-chart {
    width: 100%;
    height: 360px;
    padding: 5px 10px;
  }
  </style>
  